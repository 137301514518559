<template>
  <v-timeline :dense="$vuetify.breakpoint.xs">
    <v-timeline-item fill-dot icon="mdi-equal" large right>
      <template v-slot:opposite>
        <h2 class="headline font-weight-light">
          <DateValue long :value="today()" />
        </h2>
      </template>
      <div class="py-4">
        <v-row align="center">
          <v-col>
            <h2
              class="headline font-weight-light"
              v-if="$vuetify.breakpoint.xs"
            >
              <DateValue long :value="today()" />
            </h2>
            <span class="headline font-weight-bold primary--text">
              CHF {{ balance.toFixed(2) }}
            </span>

            <div>Saldo</div>
          </v-col>
          <v-col>
            <PersonItem :value="$_profilePerson" small />
          </v-col>
        </v-row>
      </div>
    </v-timeline-item>
    <v-timeline-item
      v-for="(item, index) in transactions"
      :key="index"
      fill-dot
      :icon="item.amount > 0 ? 'mdi-plus-thick' : 'mdi-minus-thick'"
      :color="item.amount > 0 ? 'success' : 'error'"
      right
    >
      <template v-slot:opposite>
        <h2 class="headline font-weight-light">
          <DateValue long :value="item.date" />
        </h2>
        {{ item.invoiceNumber }}
      </template>
      <div class="py-4">
        <v-row align="center">
          <v-col>
            <h2
              class="headline font-weight-light"
              v-if="$vuetify.breakpoint.xs"
            >
              <DateValue long :value="item.date" />, {{ item.invoiceNumber }}
            </h2>
            <span
              :class="`headline font-weight-bold ${
                item.amount > 0 ? 'success' : 'error'
              }--text`"
            >
              CHF {{ Math.abs(item.amount).toFixed(2) }}
            </span>

            <div>
              <strong>{{ item.event }}</strong> {{ item.description }}
            </div>
          </v-col>
          <v-col> <PersonItem :value="item.creator" small /> </v-col
        ></v-row>
      </div>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import DateValue from "common/components/DateValue.vue";
import PersonItem from "@/components/PersonItem.vue";
import { today } from "common/utils/date.js";
export default {
  components: { DateValue, PersonItem },
  props: ["type"],
  data() {
    return {
      loading: false,
      balance: 0,
      lastImport: {},
      transactions: [],
    };
  },
  watch: {
    type() {
      this.fetchData();
    },
  },
  methods: {
    today,
    async fetchData() {
      if (!this.type) {
        return;
      }
      const items = await this.apiList({
        resource: "finance/persontransaction",
        query: `person=${this.$_profilePerson.id}&type=${this.type}`,
      });
      this.balance = items.length == 0 ? 0.0 : items[items.length - 1].balance;
      this.transactions = items.reverse();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
